@import '@davita/bridge-library/scss/davita.scss';
@import '@davita/bridge-library/scss/font-icons.scss';

body {
}

// Nutrition subnav: It doesn't work in Nutrition application. It has to be root css (style.scss of UPP)
mat-ink-bar.mat-ink-bar {
  background-color: #00a8e4;
}

.header {
  background-color: unset !important;
}

.admin-dialog {
  margin: 20px;
}
